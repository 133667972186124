@import-normalize body {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  --red-color: #c81e4a;
  --blue-color: #32348e;
  --light-blue: #00adef;
  --white-color: #ffffff;
  --grey-color: #818181;
  --black-color: #000000;
  --clarify-color: #B5BCC2;
  --progress-color: #0231E8;
  --fix-color: #F9D900;
  --do-color: #81B1FF;
  --fixmr-color: #FF7FAB;
  --qa-color: #7C4DFF;
  --done-color: #6BC950;
  --review-color: #E65100;
  font-size: 20px;
  color: #000000;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
